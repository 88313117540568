import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import SEO from "../../components/seo"
import PageWrapper from "../../components/PageWrapper"
import { Container, Col, Row, Button } from "react-bootstrap"
import { useContent } from "../../hooks/useContent"
import NoAuthRedirect from "../../components/Core/NoAuthRedirect";
import PageTitle from "../../components/Core/PageTitle";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Ul = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const Content = styled.div`
    
    & .tab-content p {
        margin-bottom: 2rem;
    }

    & h2 {
        line-height: 1 !important;
    }

    $ a:hover {
        color: silver;
    }

`

const WhatYouNeedToKnow = ( { data } ) => {

    const headerConfig = {
        paddingBottom: 10,
        variant: 'minimal'
    };

    const content = useContent()

    return (
        <>
            <UnauthenticatedTemplate>
                <NoAuthRedirect />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title="What you need to know" />
                        <Content>
                            <Container>
                                <Row className="align-items-center">
                                    <Col className="col-lg-6">
                                        <Container>
                                            <Breadcrumb className="mt-20">
                                                <Breadcrumb.Item active><Link to="/">Home</Link></Breadcrumb.Item>
                                                <Breadcrumb.Item active>
                                                    {content.get('what-you-need-to-know-page-title', true)}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </Container>
                                        <PageTitle
                                            align="left"
                                            title={ content.get('what-you-need-to-know-page-title', true) }
                                        />
                                        <Container as="section" className="mb-10">
                                            <Row className="">
                                                <Col className="pb-14 pb-lg-0">
                                                    <Ul>
                                                    {[
                                                        {
                                                            icon: "fa fa-hand-holding-heart",
                                                            title: "Portal Principles",
                                                            buttonLink: "/what-you-need-to-know/portal-principles"
                                                        },
                                                        {
                                                            icon: "fa fa-exchange-alt",
                                                            title: "Looking for a new position",
                                                            buttonLink: "/what-you-need-to-know/changing-position"
                                                        },
                                                        {
                                                            icon: "fa fa-project-diagram",
                                                            title: "Looking for a short term assignment",
                                                            buttonLink: "/what-you-need-to-know/short-term-assignments"
                                                        },
                                                        {
                                                            icon: "fas fa-user-friends",
                                                            title: "Mentorship@COFRA",
                                                            buttonLink: "/what-you-need-to-know/mentorship"
                                                        },
                                                        {
                                                            icon: "fa fa-share",
                                                            title: "Referral Programme",
                                                            buttonLink: "/what-you-need-to-know/referral-programme"
                                                        },
                                                        {
                                                            icon: "fa fa-question-circle",
                                                            title: "Frequently asked questions",
                                                            buttonLink: "/what-you-need-to-know/faq"
                                                        }
                                                    ].map((item) => (
                                                            <li>
                                                                <Link to={item.buttonLink}>
                                                                    <Button
                                                                        variant="link"
                                                                        className="pl-0 ml-0 py-2 text-left" 
                                                                        >
                                                                        <i className={`${item.icon} mr-3 font-size-3`}></i>{item.title}
                                                                    </Button>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </Ul>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    <Col className="d-none d-lg-block col-6">
                                        <GatsbyImage 
                                            image={ getImage(data.pageImg) } 
                                            alt="Green lush background. Fancy lamp bulbs hanging from the ceiling"
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}
export default WhatYouNeedToKnow

export const query = graphql`
    {
        pageImg: file(relativePath: {eq: "photo_cofra_greenery.jpg"}) {
            childImageSharp {
                gatsbyImageData (
                    height: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP],
                    layout: CONSTRAINED
               )
            }
        }
    }
`
